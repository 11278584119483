import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import Scrollspy from 'react-scrollspy'
import Scroll from './scroll'
import SocialLinks from './social-links'
import EnglishLogo from '../images/logo-en.inline.svg'
import FrenchLogo from '../images/logo-fr.inline.svg'

/* eslint-disable */
const Nav = (props) => (
    <StaticQuery
        query={graphql`
            query {
                site {
                siteMetadata {
                    nav1,
                    nav2,
                    nav3,
                    nav2_link,
                    nav3_link
                }
                }
            }
        `}
        render={data => (
            <>
                <nav className={props.sticky ? 'alt' : ''}>
                    <div className="content-nav">
                        <>
                            {(process.env.GATSBY_TRANSLATION === 'en') ? <a href="http://friendsofnotredamedeparis.com/" target="_blank"><EnglishLogo className="logo"/></a> : <a href="http://notredamedeparis.fr" target="_blank"><FrenchLogo className="logo"/></a> }
                        </>
                        <div className="nav-links">
                            <Scrollspy items={ ['reconstruction', 'devenir-mecene', 'contact'] } currentClassName="is-active" offset={-300} className="menu">
                                <li onClick={close}>
                                    <Scroll type="id" element="reconstruction">
                                        <a href="/">{data.site.siteMetadata.nav1}</a>
                                    </Scroll>
                                </li>
                                <li onClick={close}>
                                    <Scroll type="id" element="devenir-mecene">
                                        <a href={data.site.siteMetadata.nav2_link}>{data.site.siteMetadata.nav2}</a>
                                    </Scroll>
                                </li>
                                <li onClick={close}>
                                    <Scroll type="id" element="contact">
                                        <a href={data.site.siteMetadata.nav3_link}>{data.site.siteMetadata.nav3}</a>
                                    </Scroll>
                                </li>
                            </Scrollspy>
                            <SocialLinks></SocialLinks>
                        </div>
                        <button id="burger" onClick={open}>
                            <i className="fas fa-bars"></i>
                        </button>
                        <button id="back" onClick={close}>
                            <i className="fas fa-times"></i>
                        </button>
                    </div>
                </nav>
            </>
        )}
    />
)

const open = () => {
    document.querySelector("nav:not(.alt)").classList.add('opened');
}

const close = (e) => {
    e.preventDefault();
    document.querySelector("nav:not(.alt)").classList.remove('opened');
}

const trackScrolling = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const floatingNav = document.querySelector("nav.alt");
    if (winScroll > 240) {
        floatingNav.classList.add('visible');
    } else {
        floatingNav.classList.remove('visible');
    }
};

if (typeof document !== 'undefined') {
    document.addEventListener('scroll', trackScrolling);
} else if (typeof window !== 'undefined') {
    window.document.addEventListener('scroll', trackScrolling);
}

export default Nav