import React from "react"
import Nav from "./nav"
import Scroll from './scroll'
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { ParallaxBanner } from 'react-scroll-parallax'
import isMobile from 'ismobilejs'

const styleImage = {
  position: 'absolute'
};

export default () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            h1
          }
        }
        file1: file(relativePath: { eq: "notredamedeparis1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2400, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    // file2: file(relativePath: { eq: "notredamedeparis2.jpg" }) {
    //   childImageSharp {
    //     fluid(maxWidth: 2400, quality: 100) {
    //       ...GatsbyImageSharpFluid
    //     }
    //   }
    // }
    // file3: file(relativePath: { eq: "notredamedeparis3.jpg" }) {
    //   childImageSharp {
    //     fluid(maxWidth: 2400, quality: 100) {
    //       ...GatsbyImageSharpFluid
    //     }
    //   }
    // }
    // file4: file(relativePath: { eq: "notredamedeparis4.jpg" }) {
    //   childImageSharp {
    //     fluid(maxWidth: 2400, quality: 100) {
    //       ...GatsbyImageSharpFluid
    //     }
    //   }
    // }
    render={data => (
        <header>
          <ParallaxBanner
            layers={[
              // {
              //     children: <Img className="carrousel-image" fluid={data.file4.childImageSharp.fluid} style={styleImage} objectFit="cover" objectPosition="50% 50%"/>,
              //     amount: 0.2
              // },
              // {
              //     children: <Img className="carrousel-image" fluid={data.file3.childImageSharp.fluid} style={styleImage} objectFit="cover" objectPosition="50% 50%"/>,
              //     amount: 0.2
              // },
              // {
              //     children: <Img className="carrousel-image" fluid={data.file2.childImageSharp.fluid} style={styleImage} objectFit="cover" objectPosition="50% 50%"/>,
              //     amount: 0.2
              // },
              {
                  children: <Img className="carrousel-image" fluid={data.file1.childImageSharp.fluid} style={styleImage} objectFit="cover" objectPosition="50% 50%"/>,
                  amount: 0.2
              }
            ]}
            disabled={isMobile.any}
            style={{
                height: '100%'
            }}
          >
          </ParallaxBanner>
          <Nav/>
          <div className="title"><h1 dangerouslySetInnerHTML={{__html: data.site.siteMetadata.h1}}></h1></div>
          <Scroll type="id" element="reconstruction"><a className="scroll-button" href="/reconstruction">Scroll</a></Scroll>
        </header>
    )}
  />
)