import React from 'react';

export default class FixedViewportHeight extends React.Component {
  state = {
    height: '100vh'
  };

  updateStyle = () => {
    this.setState({
      height: window.innerHeight + 'px'
    });
  };

  updateStyleWithTimeout = () => setTimeout(() => {
    this.updateStyle();
  }, 250);

  componentDidMount() {
    this.updateStyle();
    window.addEventListener('orientationchange', this.updateStyleWithTimeout);
  }

  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.updateStyleWithTimeout);
  }

  render() {
    return <div {...this.props} style={{position: 'relative', height: this.state.height}} />;
  }
}
