/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import FixedViewportHeight from "./fixed-viewport-height"
import Nav from "./nav"
import Scroll from "./scroll"
import SocialLinks from "./social-links"
import '../scss/main.scss'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            nav2,
            nav2_link
            creator,
            credits
          }
        }
      }
    `}
    render={data => (
      <>
        <FixedViewportHeight>
          <Header/>
        </FixedViewportHeight>
        <Nav sticky/>
        <div>
          <main>{children}</main>
        </div>
        <footer>
          <div className="content" style={{textAlign: 'center'}}>
            <Scroll type="id" element="devenir-mecene"><a className="scroll-button" href={data.site.siteMetadata.nav2_link}><h3 className="underlined">{data.site.siteMetadata.nav2}</h3></a></Scroll>
            <SocialLinks></SocialLinks>
            <div style={{textAlign: 'center'}}>
              <a href="https://www.thefarmcom.io" target="_blank" rel="noreferrer noopener" style={{textDecoration: 'none'}}>{data.site.siteMetadata.creator}<div className="thefarm">THE FARM</div></a><br/>
              <small dangerouslySetInnerHTML={{ __html: data.site.siteMetadata.credits }}/>
            </div>
          </div>
        </footer>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
