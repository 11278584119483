import React from 'react'
import EnglandFlag from "../images/england.inline.svg"
import FranceFlag from "../images/france.inline.svg"

const SocialLinks = (props) => (
    <ul className="social-links">
        <li>
            <a href="https://www.facebook.com/cathedrale.notredamedeparis/">
                <i className="fab fa-facebook"></i>
            </a>
        </li>
        <li>
            <a href="https://twitter.com/notredameparis">
                <i className="fab fa-twitter"></i>
            </a>
        </li>
        <li>
            <a href="https://www.instagram.com/notredamedeparis/">
                <i className="fab fa-instagram"></i>
            </a>
        </li>

        <li className="language">
            {(process.env.GATSBY_TRANSLATION === 'en') ? <a href="http://mecenat.notredamedeparis.fr"><FranceFlag></FranceFlag></a> : <a href="http://charity.friendsofnotredamedeparis.com"><EnglandFlag></EnglandFlag></a> }
        </li>
    </ul>
)

export default SocialLinks